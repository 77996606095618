
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog'; // Assuming you use Angular Material for pop-ups
// import { SessionPopupComponent } from '../components/session-popup/session-popup.component';
import { DialogService } from '../services/dialog.service'; // Adjust the path as needed
import { IdleService } from '../services/idle.service';
@Injectable(
  {
    providedIn: 'root'
  }
)
export class AuthInterceptor implements HttpInterceptor {

  constructor(private dialogService: DialogService, private idleService: IdleService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.idleService.resetIdleTimer();
  // console.log('###### Request intercepted', request, next, this);
  // return next.handle(request); //if you have exeption on undefined error or missing object error then uncomment this
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // in case of login from portal the login api fails with 401
        if (error.status === 401 && error.url.split('/').slice(-2).join('/') !== 'v1/logout' && error.url.split('/').slice(-2).join('/') !== 'merchant/login' ) {
          // Open pop-up when a 401 error is intercepted
          this.dialogService.openUnauthorizedDialog();
        }
        return throwError(error); // for some reason ()=>error is not working thus using throwError(error) instead of throwError(()=>error)
      })
    );
  }
}
