import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {SiginAction} from './sigin.action';
import {ApiErrorModel} from '../../data/model/ApiErrorModel/api-error.model';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';
import {AppConstants} from '../../shared/constants/app-constants';
import {PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {AlertMessageDialogComponent} from '../../dialogs/alert-message-dialog/alert-message-dialog.component';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {SettingsComponent} from '../../merchant/components/settings/settings.component';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent extends SiginAction implements OnInit {
  email: string;
  password: string;
  username: string;
  forgotpassword: boolean;
  signin: boolean;
  forgot: string;
  keys: String[];
  keys1: String[];
  topErrorMessage: string;
  currentUserName: string;
  rememberMe: Boolean = false;
  isEnabled: Boolean = false;
  tw0fa: Boolean = false;
  tw0faCode: string;
  settings: SettingsComponent;
  urlBatchRedirect: string;

  get currentAccountId(): any {
    const currentAccountid = !this.isNullOrUndefined(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID)) ? JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID)) : '';
    return currentAccountid;
  }


  constructor(dataManager: DataManagerService, snackBar: MatSnackBar,
              router: Router, @Inject(PLATFORM_ID) private platformId: any, private dialog: MatDialog,
              @Inject('LOCALSTORAGE') private localStorage: any) {
    super();
    this.snackBar = snackBar;
    this.signin = true;
    this.forgotpassword = false;
    this.forgot = '/forgot';
    this.dataManager = dataManager;
    this._sigInUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_LOGIN;
    this._getAccountInfoUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT + '?limit=100' + '&page=1';
    this.urlBatchRedirect = '';
    this._forgotPassword = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_FORGOT_PASSWORD + this.forgot;
    this.router = router;
    this.tw0faCode = '';
  }

  ngOnInit(): void {
    this.autoLogin();
  }

  autoLogin() {
    const accessTokenObj = this.localStorage.getItem('access_token');
    const rememberMe = JSON.parse(this.localStorage.getItem('rememberMe'));
    const userMenu = JSON.parse(this.localStorage.getItem('user_menu'));
    if ((accessTokenObj || rememberMe == true) && !this.isNullOrUndefined(userMenu) && userMenu.length > 0) {
      console.log('auto login');
      this.router.navigate(['/home']);

    }
      // else {
    //   this.router.navigate(['/signin/step-verification']);
    // }
  }

  protected passSiginResponse(res: any) {
    this.loadFlag = false;
    if (res.message === "2FA Required.") {
      this.tw0fa = true;
      return;
    }
    this.tw0fa = false;
    console.log('sign_in response', res);
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.setItem(AppConstants.STORAGE_KEY_ACCESS_TOKEN, res.response_content.token_type
        + ' ' + res.response_content.access_token);
      this.localStorage.setItem(AppConstants.STORAGE_KEY_USER_MENU, JSON.stringify(res.menu));
      this.localStorage.setItem(AppConstants.STORAGE_KEY_FIRST_MENU, JSON.stringify(res.menu[0] ? res.menu[0].name : res.menu[0]));
      this.localStorage.setItem(AppConstants.STORAGE_KEY_USER_PERMISSIONS, JSON.stringify(res.permissions));
      res.user.login_as_merchant = res.login_as_merchant;
      this.localStorage.setItem(AppConstants.STORAGE_KEY_USER, JSON.stringify(res.user));
      this.localStorage.setItem(AppConstants.STORAGE_KEY_USER_ID, JSON.stringify(res.user_id));
      this.localStorage.setItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID, JSON.stringify(res.current_account_id));
      this.localStorage.setItem(AppConstants.STORAGE_KEY_USER_NAME, JSON.stringify(res.user.name));
      this.localStorage.setItem(AppConstants.STORAGE_KEY_USER_EMAIL, JSON.stringify(res.user.email));
    }
    this.is_2fa_phone_verified = res.user.is_2fa_phone_verified;
    this.is_2fa_email_verified = res.user.is_2fa_email_verified;
    this.dataManager.accessToken = res.response_content.token_type + ' ' + res.response_content.access_token;
    this.dataManager.createHeaderWithUersAccessToken();
    // if (this.is_2fa_email_verified != '0') {
    //   this.router.navigate(['signin/step-verification'], {relativeTo: this.route});
    // } else {
      this.dataManager.user = res.user;
      this.dataManager.menuArray = res.menu ? res.menu : [];
      this.dataManager.permissionsArray = res.permissions;
      if (isNotNullOrUndefined(this.dataManager.userAccountInfoObject)) {
        this.getAccountInfo();
        this.showSnackBar('Successfully logged ln', 'OK', 3000);
        this.routeNavigate('/' + this.dataManager.menuArray[0].routerLink);
      }
      this.settings = new SettingsComponent(this.dataManager, this.localStorage);
    // }
  }

  protected passSignInError(error: ApiErrorModel) {
    this.tw0fa = false;
    this.loadFlag = false;
    console.log(error);
    this.showSnackBar(error.message, 'Ok', 3000);
    this.loadFlag = false;
    this.errorMsg = error.message;
    this.topErrorMessage = error.errors;
    this.keys = Object.keys(this.topErrorMessage);
    this.keys1 = Object.values(this.topErrorMessage);
    console.log(this.errorMsg);
    let i;
    let j;
    let name = ['username', 'password'];
    for (i = 0; i < this.keys.length; i++) {
      for (j = 0; j < name.length; j++) {
        if (this.keys[i].match(name[j])) {
          document.getElementById(name[j]).style.borderColor = '#a94442';
          document.getElementById(name[j] + 1).style.display = 'block';
        }
      }
    }
  }

  signIn(): void {
    this.loadFlag = true;
    const postBody = {};
    postBody['username'] = this.username;
    postBody['password'] = this.password;
    postBody['2fa_code'] = this.tw0faCode;
    if (this.rememberMe) {
      localStorage.setItem('rememberMe', JSON.stringify(this.rememberMe));
    }
    this.userSignIn(postBody);
  }

  redirectToForgotPassword() {
    this.routeNavigate('/signin/forgotpassword');
  }

  redirectToRegister() {
    this.routeNavigate('/register');
  }

  protected passError(error) {
    this.loadFlag = false;
    console.log(error);
    if (error) {
      const data = {
        message: error.message,
        disableClose: true
      };
      this.dialog.openDialogs.forEach(setupDialog => {
        if (setupDialog) {
          this.loadFlag = false;
          setupDialog.close();
        }
      });
      const dialog = this.dialog.open(AlertMessageDialogComponent, {data});

      dialog.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          console.log();
          this.dataManager.logOut();
        }
      });

    }
  }

  protected passAccountInfoResponse(res) {
    console.log('account info', res);
    this.loadFlag = false;
    this.dataManager.userAccountInfoObject = res.data;
    for (let i = 0; i < res.data.length; i++) {
      if (res.data[i].id === this.currentAccountId) {
        this.currentUserName = res.data[i].account_name;
      }
    }
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.setItem(AppConstants.STORAGE_KEY_ACCOUNT_ID, JSON.stringify(res.data));
      this.localStorage.setItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_NAME, JSON.stringify(this.currentUserName));
    }
  }

  onKeydown(event) {
    if (event.key === 'Enter') {
      console.log(event);
      this.signIn();
    }
  }

  protected passGetSettingError(error) {

  }
}
